import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { isLinkModule, isPayModule } from '@/utils/apexModuleHelper';

interface IPaymentAppGlobalState {
  // a loading gif on app-level, each page should handle it itsself
  appLoading: boolean;
  theme?: BeamoNS.IThemeV2;
}

const initialState: IPaymentAppGlobalState = {
  appLoading: !isPayModule && !isLinkModule,
};

export const paymentAppGlobalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    // Update other information (like product response)
    updatePaymentAppGlobalState: (state, action: PayloadAction<Partial<IPaymentAppGlobalState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updatePaymentAppGlobalState } = paymentAppGlobalSlice.actions;

export default paymentAppGlobalSlice.reducer;
