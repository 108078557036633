import { ReactNode } from 'react';
import { message } from 'antd';

const rejectedMessages = [
  'user rejected',
  // https://breezecash-3d104e39e.sentry.io/issues/5011405409/?project=4505791306727424&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=7d&stream_index=14
  'user canceled',
  // https://breezecash-3d104e39e.sentry.io/issues/4906445299/?project=4505791306727424&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=7d&stream_index=2
  'user disapproved',
];

const sentryIgnoredMessages = [...rejectedMessages];

export const kycNotSupportedStates = ['LA', 'AK', 'NY'];

export const isSentryIgnoredError = (err: Error) => {
  if (typeof err?.message !== 'string') return false;
  const lowercaseMessage = err.message.toLowerCase();
  return sentryIgnoredMessages.some((msg) => lowercaseMessage.includes(msg));
};

export const isUserRejectedError = (err: Error) => {
  if (typeof err?.message !== 'string') return false;
  const lowercaseMessage = err.message.toLowerCase();
  return rejectedMessages.some((msg) => lowercaseMessage.includes(msg));
};

export const getErrorMessage = (error: TAnyType) => {
  const response = error.response;
  return response?.data?.errorMessage || response?.data?.errorCode || error.message || '';
};

export const commonRequestErrorProcess = (error: TAnyType) => {
  message.error(getErrorMessage(error));
};

export enum EBreezeErrorType {
  INSUFFICIENT_BALANCE = 0,
  APPROVAL_FAIL,
  UNKNOWN,
  TIMEOUT,
  DIFFERENT_CHAIN,
  NO_SUPPORTED_TOKEN,
  USER_REJECTED_TRANSACTION,
  ONLY_DESC,
}

export class BreezeError extends Error {
  public type: EBreezeErrorType;
  public extra: any;
  public customMsg?: string | ReactNode;

  constructor({
    type = EBreezeErrorType.ONLY_DESC,
    customMsg,
    extra,
  }: {
    type?: EBreezeErrorType;
    customMsg?: string | ReactNode;
    extra?: any;
  }) {
    super(EBreezeErrorType[type]);
    this.name = 'BreezeError';

    this.type = type;
    this.extra = extra;
    this.customMsg = customMsg;

    Object.setPrototypeOf(this, BreezeError.prototype);
  }
}
