import { ReactNode, useEffect, useState } from 'react';
import Div100vh from 'react-div-100vh';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import {
  browserTracingIntegration,
  captureConsoleIntegration,
  init as SentryInit,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { onAuthStateChanged, Unsubscribe, User } from 'firebase/auth';
import { debounce } from 'lodash';

import Spinner from '@/components/spinner';

import { useTitle } from './hooks/useTitle';
import { BeamoProvider } from './services/beamo/provider';
import { queryClient } from './services/beamo/queryClient';
import { auth, checkCookieCredentialPromise } from './services/firebaseAuth';
import { isPaymentApp } from './utils/apexModuleHelper';
import { isSentryIgnoredError } from './utils/commonErrors';
import { store } from './store';
// don't delete
// import './mock';

if (process.env.NODE_ENV !== 'development') {
  SentryInit({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      browserTracingIntegration(),
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/[^/]+\.breeze.cash\//],
    // Performance Monitoring
    tracesSampleRate: 0.1,

    beforeSend(event, hint = {}) {
      const originalException = hint.originalException as Error;
      if (isSentryIgnoredError(originalException)) {
        // ignore user rejected error
        return null;
      } else if (originalException?.name === 'ChunkLoadError') {
        // refresh to invalidate cache
        window.location.reload();
        return null;
      }
      return event;
    },
    // filter irrelevant errors
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // https://github.com/orgs/WalletConnect/discussions/3291
      'No matching key.',
      'WebSocket connection failed for host: wss://relay.walletconnect.com',
      // dynamic internal error
      // https://breezecash-3d104e39e.sentry.io/issues/4918362621/?project=4505791306727424&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=7d&stream_index=13
      'ZodError',
      'User rejected',
      'User canceled',
      'User disapproved',
      'AppCheck: ReCAPTCHA error',
    ],
    denyUrls: [
      // hotjar
      /static\.hotjar\.com/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
}

const onResize = debounce(() => {
  if (window.innerWidth < 768) {
    document.body.classList.add('mobile');
    document.body.classList.remove('pc');
  } else {
    document.body.classList.remove('mobile');
    document.body.classList.add('pc');
  }
}, 80);
window.addEventListener('resize', onResize);
onResize();

function Root({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(auth.currentUser);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useTitle('Breeze');

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    const signin = async () => {
      await checkCookieCredentialPromise;

      unsubscribe = onAuthStateChanged(auth, async (_user) => {
        setUser(_user);
        console.log('[Breeze] Set user in App.tsx,', _user?.email, _user?.uid);
        setIsLoading(false);
      });
    };

    signin();

    return () => {
      unsubscribe?.();
    };
  }, []);

  return (
    <Div100vh>
      {isLoading ? (
        <div className='flex h-[100vh] items-center justify-center'>
          <Spinner size={64} />
        </div>
      ) : (
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <BeamoProvider key={user?.uid} user={user}>
              {children}
            </BeamoProvider>
          </Provider>
        </QueryClientProvider>
      )}
    </Div100vh>
  );
}

const root = createRoot(document.getElementById('root') as HTMLElement);
if (isPaymentApp) {
  import('./apexModules/payment-page').then((PaymentPageApp) => {
    root.render(
      <Root>
        <PaymentPageApp.default />
      </Root>
    );
  });
} else {
  import('./DashboardApp').then((DashboardApp) => {
    root.render(
      <Root>
        <DashboardApp.default />
      </Root>
    );
  });
}
