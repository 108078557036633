import { captureException } from '@sentry/browser';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import * as rax from 'retry-axios';

import { grecaptchaExecute } from '@/components/recaptcha/recaptchaFallback';
import { showV2ReCaptchaElement } from '@/components/recaptcha/recaptchaModal';
import { EHttpRequestHeader } from '@/constants/enum';
import { getLivemode } from '@/hooks/useLivemode';
import { accountReadyLock, userReadyLock } from '@/services/firebaseAuth';
import { getUrlParam, isProdDeployEnv } from '@/utils';
import { isDashboardModule } from '@/utils/apexModuleHelper';
import { getLocalRoleInfo } from '@/utils/role';

export const API_VERSION = 'v1';

export const API_BASE_URL = `${process.env.API_HOST}/${API_VERSION}`;

export interface IPagingQuery {
  limit?: number;
  offset?: number;
}

export interface IBaseTimeStamp {
  createdAt: number;
  updatedAt?: number;
}

export interface IArrayData<T> {
  count: number;
  results: T[];
}
export interface IApiResponseV1<T> {
  status: 'SUCCEEDED' | 'FAILED';
  errorCode?: string;
  errorMessage?: string;
  data: T;
  hasMore?: boolean;
}

export interface IApiResponseError {
  status: 'FAILED';
  errorCode?: string;
  errorMessage?: string;
}

let lastReqUrl = '';
let lastReqData: any = null;
let lastReqHeaders: any = null;

export const createBaseAxios = ({
  needAuthToken,
  useLivemode,
  needReCaptcha,
}: { needAuthToken?: boolean; useLivemode?: boolean; needReCaptcha?: boolean } | undefined = {}) => {
  const options: AxiosRequestConfig = {
    baseURL: API_BASE_URL,
    timeout: 20 * 1000,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };

  const instance = axios.create(options);

  instance.defaults.raxConfig = {
    retry: needReCaptcha ? 20 : 3,
    backoffType: 'static',
    retryDelay: needReCaptcha ? 2000 : 100,
    onRetryAttempt: (err) => {
      // TODO: To be removed when https://github.com/axios/axios/issues/5089 gets closed.
      err.config!.headers = JSON.parse(JSON.stringify(err.config?.headers || {}));
    },
    // Override the decision making process on if you should retry
    shouldRetry: (err) => {
      const { currentRetryAttempt = 0, retry = 0 } = rax.getConfig(err) || {};
      if (currentRetryAttempt >= retry) return false; // ensure max retries is always respected

      if (err.message === 'Network Error') return true;

      if (
        needReCaptcha &&
        (err.response?.data as { errorCode: string; errorMessage: string })?.errorCode === 'RECAPTCHA_CHALLENGE_FAILED'
      ) {
        showV2ReCaptchaElement(err.response);
        return true;
      }

      // Handle the request based on your other config options, e.g. `statusCodesToRetry`
      return rax.shouldRetryRequest(err);
    },
  };
  rax.attach(instance);

  instance.interceptors.request.use(async (config) => {
    if (needAuthToken && !config.headers['Authorization']) {
      if (!(config.url === '/accounts' && config.method === 'get')) {
        await accountReadyLock.promise;
      }
      const currentUser = await userReadyLock.promise;
      const token = await currentUser?.getIdToken();
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
    }
    if (useLivemode) {
      config.params = {
        livemode: getLivemode(),
        ...config.params,
      };
    }

    if (needReCaptcha) {
      const { token: recaptchaToken, version: recaptchaVersion } = await grecaptchaExecute(config.url || 'api');
      config.headers['g-recaptcha-response'] = recaptchaToken;
      config.headers['g-recaptcha-version'] = recaptchaVersion;
      if (!isProdDeployEnv && getUrlParam('recaptcha') === '0') {
        config.headers['g-recaptcha-version'] = 0;
      }
    }

    // viewer
    const { selectedAccount } = getLocalRoleInfo() || {};
    if (selectedAccount && config.url !== '/accounts' && isDashboardModule) {
      config.headers![EHttpRequestHeader.ACCOUNT] = selectedAccount;
    }
    lastReqUrl = config.url || '';
    lastReqData = config.data;
    lastReqHeaders = config.headers;
    return config;
  });

  instance.interceptors.response.use(
    (res) => {
      let ret = undefined;

      if (res.request instanceof XMLHttpRequest) {
        ret = res.data?.data;
      } else {
        // retry-axios retry's returning only contain response.data.data
        ret = res;
      }

      if (!ret) {
        throw new AxiosError('No data in response', undefined, res.config, res.request, res);
      }
      return ret;
    },
    (error) => {
      // FIXME
      // if (error.response.data?.errorCode === 'AUTHORIZATION_FAILED') {
      //   // to avoid circular dependency
      //   const { signOutBeamo } = await import('@/services/firebaseAuth');
      //   await signOutBeamo();
      //   location.href = '/login';
      // }
      const response = error.response || {};
      const reqUrl: string = response.config?.url || '';
      const resData = response.data;
      let captureError = true;
      if (resData) {
        const { errorCode, errorMessage = '' } = resData as { errorCode: string; errorMessage: string };
        switch (reqUrl) {
          case '/accounts':
            if (errorCode === 'ACCOUNT_DOES_NOT_EXISTS') {
              captureError = false;
            }
            break;
          case '/merchants':
            if (errorCode === 'MERCHANT_NOT_FOUND') {
              captureError = false;
            }
            break;
          case '/authentications/authenticator':
            if (errorCode === 'AUTHENTICATOR_DOES_NOT_EXISTS') {
              captureError = false;
            }
            break;
          case '/gasless_send_cryptos/get_transaction':
            if (errorMessage.startsWith('Amount is too small')) {
              captureError = false;
            }
            break;
          case '/authentications/login':
            if (
              errorCode === 'VERIFY_CODE_EXPIRED' ||
              errorCode === 'VERIFY_CODE_DOES_NOT_EXISTS' ||
              errorCode === 'ACCOUNT_DOES_NOT_EXISTS'
            ) {
              captureError = false;
            }
            break;
          case '/authentications/verify_code/email':
          case '/offramp/loggedinclient/init/validate':
            if (
              errorCode === 'CONNECT_INVALID_EMAIL' ||
              errorCode === 'OFFRAMP_INVALID_EMAIL' ||
              errorCode === 'PAYMENT_PAGE_INVALID_EMAIL'
            ) {
              captureError = false;
            }
            break;
          default:
            if (
              errorMessage.includes('must be a valid email') ||
              errorCode === 'PAYMENT_DECLINED_RISK' ||
              errorCode === 'BANK_NOT_SUPPORTED'
            ) {
              captureError = false;
            }
        }
      }

      if (captureError) {
        // functions/src/common/db.ts
        const hashedIds = /(creq|ofrprq|page|pcs)_[a-f0-9]+/g;
        let extraParams = {};
        if (reqUrl) {
          extraParams = {
            reqHeaders: response.config?.headers,
            reqData: response.config?.data,
            reqUrl,
            statusText: response.statusText,
            resData,
          };
        } else {
          extraParams = {
            lastReqUrl,
            lastReqData,
            lastReqHeaders,
          };
        }
        captureException(error, {
          fingerprint: ['api-base', reqUrl.replace(hashedIds, '')],
          extra: extraParams,
        });
      }

      return Promise.reject(error as AxiosError<IApiResponseError>);
    }
  );

  return instance;
};

export const authedInstance = createBaseAxios({ needAuthToken: true });
export const authedInstanceWithLivemode = createBaseAxios({ needAuthToken: true, useLivemode: true });
export const unauthedInstance = createBaseAxios();
export const reCaptchaInstance = createBaseAxios({ needReCaptcha: true });
