export const enum EOfframpStepStatus {
  OVERVIEW = 0,
  EMAIL_COLLECTION,
  AUTHENTICATE,
  FIREBASE_PROCESSING,
  PROCESSING,
  REVIEW,
  SUCCESS,
  FAIL,
  REQUEST_ID_ERROR,
}

export const enum EOfframpInitStatus {
  CREATED = 'CREATED',
  SUCCEEDED = 'SUCCEEDED',
  WAITING_FOR_BASIC_INFO = 'WAITING_FOR_BASIC_INFO',
  WAITING_FOR_KYC = 'WAITING_FOR_KYC',
  WAITING_FOR_BANK_ACCOUNT = 'WAITING_FOR_BANK_ACCOUNT',
  ACCOUNT_INACTIVE = 'ACCOUNT_INACTIVE',
  ACCOUNT_SUSPENDED = 'ACCOUNT_SUSPENDED',
  CUSTODIAL_ACCOUNT_CLOSED = 'CUSTODIAL_ACCOUNT_CLOSED',
}

export const getOfframpInitStatusMethod = {
  isSucceeded(status: EOfframpInitStatus): boolean {
    return status == EOfframpInitStatus.SUCCEEDED;
  },

  isProcessing(status: EOfframpInitStatus): boolean {
    return [
      EOfframpInitStatus.CREATED,
      EOfframpInitStatus.WAITING_FOR_BASIC_INFO,
      EOfframpInitStatus.WAITING_FOR_KYC,
      EOfframpInitStatus.WAITING_FOR_BANK_ACCOUNT,
    ].includes(status);
  },

  isFailed(status: EOfframpInitStatus): boolean {
    return [
      EOfframpInitStatus.ACCOUNT_INACTIVE,
      EOfframpInitStatus.ACCOUNT_SUSPENDED,
      EOfframpInitStatus.CUSTODIAL_ACCOUNT_CLOSED,
    ].includes(status);
  },
};

export const enum EOfframpRequestStatus {
  CREATED = 'CREATED',
  SUCCEEDED = 'SUCCEEDED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  PENDING_USER_ONBOARDING = 'PENDING_USER_ONBOARDING',
  USER_ACCOUNT_ISSUE = 'USER_ACCOUNT_ISSUE',
  PENDING_USER_WITHDRAWAL = 'PENDING_USER_WITHDRAWAL',
  REQUIRES_RELEASE = 'REQUIRES_RELEASE',
  PENDING_DEPOSIT_CRYPTO_USER = 'PENDING_DEPOSIT_CRYPTO_USER',
  PENDING_DEPOSIT_CRYPTO_MERCHANT = 'PENDING_DEPOSIT_CRYPTO_MERCHANT',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING_MANUAL_INVESTIGATION = 'PENDING_MANUAL_INVESTIGATION',
}

export const getOfframpStatus = {
  cashoutInitiated(status?: EOfframpRequestStatus): boolean {
    return (
      !!status &&
      [
        EOfframpRequestStatus.SUCCEEDED,
        EOfframpRequestStatus.IN_PROGRESS,
        EOfframpRequestStatus.PENDING_DEPOSIT_CRYPTO_MERCHANT,
      ].includes(status)
    );
  },
  cashoutInReview(status?: EOfframpRequestStatus): boolean {
    return status === EOfframpRequestStatus.PENDING_MANUAL_INVESTIGATION;
  },
  cashoutFailed(status?: EOfframpRequestStatus): boolean {
    return (
      !!status &&
      [
        EOfframpRequestStatus.CANCELED,
        EOfframpRequestStatus.EXPIRED,
        EOfframpRequestStatus.USER_ACCOUNT_ISSUE,
      ].includes(status)
    );
  },
};

export const ENABLED_COUNTRYIES = [
  'US',
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IS',
  'IT',
  'LI',
  'LT',
  'LU',
  'LV',
  'MC',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
  'SG',
  'SM',
  'CH',
  'GB',
];
